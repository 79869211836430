<template>
  <div class="e_store-container min-h-screen my-5">
    <div class="text-xl font-bold text-e_store-light mb-7">
      {{ t('profile') }}
    </div>

    <MyAccountProfileImage v-if="isAuthLoggedIn()" />

    <MyAccountProfileLinks />
  </div>
</template>

<script setup lang="ts">

const { isAuthLoggedIn } = useMenuModel()
const { t } = useI18n()
</script>
