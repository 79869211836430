<template>
  <RikazExpandableSidebar
    v-model="isOpen"
    class="py-2"
    sidebar="bg-bg_alt_color rounded p-2 shadow"
    open-width="w-48"
    closed-width="w-9"
  >
    <template #sidebar>
      <button
        class="w-5 h-5 mb-2 ltr:mr-auto rtl:ml-auto"
        @click="isOpen = !isOpen"
      >
        <v-icon icon="mdi mdi-menu" :size="20" />
      </button>
      <EStoreLink
        v-for="(link, index) in links"
        :key="`profile-link-${index}`"
        :to="link.route"
        :aria-label="link.title"
      >
        <MyAccountProfileLinksLinkItem
          :is-open="isOpen"
          :icon="link.icon"
          :title="link.title"
          :home-profile-is-active="false"
          @click="closeSidebar"
        />
      </EStoreLink>

      <a :href="whatsappNumberLink" class="link" target="_blank">
        <MyAccountProfileLinksLinkItem
          :is-open="isOpen"
          icon="mdi mdi-whatsapp"
          :title="t('contact_via_whatsApp')"
          :home-profile-is-active="false"
        />
      </a>
      <MyAccountProfileLinksLinkItem
        :is-open="isOpen"
        icon="mdi mdi-logout"
        :title="t('logout')"
        :home-profile-is-active="false"
        @click="logout"
      />
    </template>
    <template #separator>
      <div class="flex-shrink-0 w-1 sm:w-5"></div>
    </template>
    <template #content>
      <NuxtPage />
    </template>
  </RikazExpandableSidebar>
</template>

<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
import { usePlacesStore } from '~/store/places'
import { useNotificationStore } from '~~/store/notifications'
const { store } = useDomainState()
const isOpen = ref(false)
const notifications = useNotificationStore()
const { signOut } = useAuth()
const { t } = useI18n()
const screenWidth = 768

defineProps<{
  whatsappNumberLink: string
}>()
let links = [
  {
    title: t('profile'),
    icon: 'mdi mdi-account-outline',
    route: '/my-account'
  },
  {
    title: t('wishlist'),
    icon: 'mdi mdi-heart',
    route: '/my-account/wishlist'
  },

  {
    title: t('notifications'),
    icon: 'mdi mdi-bell-outline',
    route: '/my-account/notifications'
  },
  {
    title: t('saved_places'),
    icon: 'mdi mdi-map-marker',
    route: '/my-account/places'
  },

  {
    title: t('money_movements'),
    icon: 'mdi mdi-currency-usd',
    route: '/my-account/money-movements'
  }
]
if (store.value?.active_package?.package?.support_invoices) {
  links.push({
    title: t('orders'),
    icon: 'mdi mdi-card-account-phone-outline',
    route: '/my-account/orders'
  })
}
onMounted(() => {
  isOpen.value = window.innerWidth > screenWidth
})
const { clearCache } = usePlacesStore()
async function logout() {
  notifications.setUnRead(0)

  if (localStorage.getItem('e_store.firebaseToken')) {
    try {
      await useBasicFetch(
        getAllRoutes().profileRoutes.deleteInstanceIdByServer,
        {
          method: 'POST',
          body: {
            token: localStorage.getItem('e_store.firebaseToken')
          }
        }
      )

      localStorage.removeItem('e_store.firebaseToken')
      clearCache()
    } catch (error) {}
  }

  await signOut({
    callbackUrl: '/',
    redirect: false
  })

  window.location.pathname = '/'
}

function closeSidebar() {
  if (isOpen.value) {
    isOpen.value = window.innerWidth > screenWidth
  }
}
</script>

<style scoped>
.router-link-exact-active {
  @apply text-nav_color !important;
}
</style>
